.patrol-form-container {
	margin-top: 50px;
}

.glyphicon-remove:before {
    content: "x";
}

.glyphicon-plus:before {
    content: "+ Add another member";
}

.array-item {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
}