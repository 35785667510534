.new-activity {
	text-align: right;
	margin-top: 60px;
}

.filters-form fieldset {
	width: 100%;
}

.filters-submit {
	float: right;
}

.list-group {
	width: 100%;
	margin-top: 60px;
}
b {
	margin-right: 5px;
}
.info {
	display: none;
}
.info.show {
	display: block;
}
.list-group-item {
	margin-bottom: 10px;
	border: 2px solid #ddd;
	flex-flow: wrap;
}
.list-group-data div{
	flex: 0 0 auto;
    margin-right: 20px;
}
.list-group-data {
	margin-bottom: 10px;
	display: flex;
	flex-flow: row wrap;
}
.btn-switch {
	background-color: #eee;
}
.ActivityList .row {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	width: 100%;
}
.ActivityList .row > div {
	flex: 1 1 70%;
}
.ActivityList .row .actions {
	flex: 0 1 auto;
}
.ActivityList .no-results {
	font-size: 18px;
}
.ActivityList .form-group.field.field-string {
    width: 170px;
    display: inline-block;
}
.hidden {
	display: none;
}
.ActivityList .calculated-data {
	background-color: skyblue;
    padding: 10px;
}
