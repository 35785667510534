.menu {
  text-align: center;
}

.menu > div {
  padding: 20px;
}

.menu a {
  width: 300px;
}