.Form {
  max-width: 1045px;
  margin: 0 auto;
  padding: 20px;
}

.radio {
  display: inline-block;
  margin-right: 10px;
}

.radio input {
  margin-right: 5px;
}

.root_coordinates_latitude,
.root_coordinates_longitude,
.root_tsLongitude,
.root_tsLatitude,
.root_activityType,
.root_subActivityType,
.root_plasticNestMarker,
.root_woodenNestMarker,
.root_tagFoundL,
.root_tagFoundR,
.root_tagNewL,
.root_tagNewR,
.root_tagByL,
.root_tagByR,
.root_ccl,
.root_ccw,
.root_numberOfEggs,
.root_totalUnhatched,
.root_newEggCount,
.root_eggsbroken,
.root_startTransfer,
.root_endTransfer,
.root_originalTec,
.root_tsTec,
.root_originalBec,
.root_tsBec,
.root_originalWecT,
.root_tsWecT,
.root_originalWecB,
.root_tsWecB,
.root_exhwm,
.root_exbec,
.root_emergeLive,
.root_emergeDead,
.root_lp,
.root_dp,
.root_dft,
.root_lft,
.root_hatchDate,
.root_excavationDate,
.root_gpsNumber,
.root_waypoint,
.root_tsGpsNumber,
.root_tsWaypoint,
.root_uhne,
.root_uhe,
.root_ffe,
.root_predated,
.root_indetermined {
  width: 50%;
  display: inline-block;
}

.root_eggsSeen, .root_turtleSeen, .root_excavated {
  width: 33%;
  display: inline-block;
}

.root_tracks_tracksA, .root_tracks_tracksB, .root_tracks_tracksC, .root_tracks_tracksD {
  width: 25%;
  display: inline-block;
  vertical-align: top;
}

fieldset > div {
  padding: 5px;
}

.root_coordinates {
  padding: 0;
}

.required {
  color: #f00;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.react-datepicker__time-list {
  padding: 0;
}
