.new-button {
	float: right;
  margin-bottom: 20px;
}

.list-group {
	width: 100%;
	margin-top: 60px;
}
b {
	margin-right: 5px;
}
.info {
	display: none;
}
.info.show {
	display: block;
}
.first-button {
	margin-right: 16px;
}
