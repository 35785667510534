.new-button {
	float: right;
  margin-bottom: 20px;
}

.list-group {
	width: 100%;
	margin-bottom: 40px;
}
.list-group-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}
