body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

.Form {
  max-width: 1045px;
  margin: 0 auto;
  padding: 20px;
}

.radio {
  display: inline-block;
  margin-right: 10px;
}

.radio input {
  margin-right: 5px;
}

.root_coordinates_latitude,
.root_coordinates_longitude,
.root_tsLongitude,
.root_tsLatitude,
.root_activityType,
.root_subActivityType,
.root_plasticNestMarker,
.root_woodenNestMarker,
.root_tagFoundL,
.root_tagFoundR,
.root_tagNewL,
.root_tagNewR,
.root_tagByL,
.root_tagByR,
.root_ccl,
.root_ccw,
.root_numberOfEggs,
.root_totalUnhatched,
.root_newEggCount,
.root_eggsbroken,
.root_startTransfer,
.root_endTransfer,
.root_originalTec,
.root_tsTec,
.root_originalBec,
.root_tsBec,
.root_originalWecT,
.root_tsWecT,
.root_originalWecB,
.root_tsWecB,
.root_exhwm,
.root_exbec,
.root_emergeLive,
.root_emergeDead,
.root_lp,
.root_dp,
.root_dft,
.root_lft,
.root_hatchDate,
.root_excavationDate,
.root_gpsNumber,
.root_waypoint,
.root_tsGpsNumber,
.root_tsWaypoint,
.root_uhne,
.root_uhe,
.root_ffe,
.root_predated,
.root_indetermined {
  width: 50%;
  display: inline-block;
}

.root_eggsSeen, .root_turtleSeen, .root_excavated {
  width: 33%;
  display: inline-block;
}

.root_tracks_tracksA, .root_tracks_tracksB, .root_tracks_tracksC, .root_tracks_tracksD {
  width: 25%;
  display: inline-block;
  vertical-align: top;
}

fieldset > div {
  padding: 5px;
}

.root_coordinates {
  padding: 0;
}

.required {
  color: #f00;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.react-datepicker__time-list {
  padding: 0;
}

.menu {
  text-align: center;
}

.menu > div {
  padding: 20px;
}

.menu a {
  width: 300px;
}
.new-activity {
	text-align: right;
	margin-top: 60px;
}

.filters-form fieldset {
	width: 100%;
}

.filters-submit {
	float: right;
}

.list-group {
	width: 100%;
	margin-top: 60px;
}
b {
	margin-right: 5px;
}
.info {
	display: none;
}
.info.show {
	display: block;
}
.list-group-item {
	margin-bottom: 10px;
	border: 2px solid #ddd;
	flex-flow: wrap;
}
.list-group-data div{
	flex: 0 0 auto;
    margin-right: 20px;
}
.list-group-data {
	margin-bottom: 10px;
	display: flex;
	flex-flow: row wrap;
}
.btn-switch {
	background-color: #eee;
}
.ActivityList .row {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
	width: 100%;
}
.ActivityList .row > div {
	flex: 1 1 70%;
}
.ActivityList .row .actions {
	flex: 0 1 auto;
}
.ActivityList .no-results {
	font-size: 18px;
}
.ActivityList .form-group.field.field-string {
    width: 170px;
    display: inline-block;
}
.hidden {
	display: none;
}
.ActivityList .calculated-data {
	background-color: skyblue;
    padding: 10px;
}

.new-button {
	float: right;
  margin-bottom: 20px;
}

.list-group {
	width: 100%;
	margin-top: 60px;
}
b {
	margin-right: 5px;
}
.info {
	display: none;
}
.info.show {
	display: block;
}
.first-button {
	margin-right: 16px;
}

.patrol-form-container {
	margin-top: 50px;
}

.glyphicon-remove:before {
    content: "x";
}

.glyphicon-plus:before {
    content: "+ Add another member";
}

.array-item {
	padding: 20px;
	display: flex;
	justify-content: space-between;
	flex-flow: row wrap;
}
.login-button, p {
  text-align: center;
}

.form-container {
  max-width: 500px;
  margin: 50px auto;
}

.new-button {
	float: right;
  margin-bottom: 20px;
}

.list-group {
	width: 100%;
	margin-bottom: 40px;
}
.list-group-item {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.new-button {
	float: right;
  margin-bottom: 20px;
}

.list-group {
	width: 100%;
	margin-bottom: 40px;
}
.list-group-item {
	display: flex;
	justify-content: space-between;
}

.content {
  max-width: 1045px;
  margin: 0 auto;
  padding: 20px;
}

.radio {
  display: inline-block;
  margin-right: 10px;
}

.radio input {
  margin-right: 5px;
}

.root_longitude,
.root_latitude,
.root_activityType,
.root_subActivityType,
.root_plasticNestMarker,
.root_woodenNestMarker,
.root_tagFoundL,
.root_tagFoundR,
.root_tagNewL,
.root_tagNewR,
.root_tagByL,
.root_tagByR,
.root_ccl,
.root_ccw,
.root_activityDate,
.root_activityTime {
  width: 50%;
  display: inline-block;
}

.root_eggsSeen, .root_turtleSeen, .root_excavated {
  width: 33%;
  display: inline-block;
}

.root_tracksA, .root_tracksB, .root_tracksC, .root_tracksD {
  width: 25%;
  display: inline-block;
  vertical-align: top;
}

fieldset > div {
  padding: 5px;
}

.required {
  color: #f00;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.react-datepicker__time-list {
  padding: 0;
}

img {
  max-width: 100%;
}
