.content {
  max-width: 1045px;
  margin: 0 auto;
  padding: 20px;
}

.radio {
  display: inline-block;
  margin-right: 10px;
}

.radio input {
  margin-right: 5px;
}

.root_longitude,
.root_latitude,
.root_activityType,
.root_subActivityType,
.root_plasticNestMarker,
.root_woodenNestMarker,
.root_tagFoundL,
.root_tagFoundR,
.root_tagNewL,
.root_tagNewR,
.root_tagByL,
.root_tagByR,
.root_ccl,
.root_ccw,
.root_activityDate,
.root_activityTime {
  width: 50%;
  display: inline-block;
}

.root_eggsSeen, .root_turtleSeen, .root_excavated {
  width: 33%;
  display: inline-block;
}

.root_tracksA, .root_tracksB, .root_tracksC, .root_tracksD {
  width: 25%;
  display: inline-block;
  vertical-align: top;
}

fieldset > div {
  padding: 5px;
}

.required {
  color: #f00;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%
}

.react-datepicker__time-list {
  padding: 0;
}

img {
  max-width: 100%;
}